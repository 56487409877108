import Vue from 'vue'
import { defineStore } from 'pinia'

export const use_infoscreens = defineStore('infoscreens', {
  state: () => ({
    gym: {},
    challenges: [],
  }),

  actions: {
    async fetch_gym_challenges_rankings(challenge_ids) {
      // const response = await Vue.smcb_axios.post(`${this.gym.slug}/infoscreens/challenge_rankings`, { challenge_ids: challenge_ids })
      const response = await Vue.smcb_axios.post(`challenge_rankings`, { challenge_ids: challenge_ids })
      // const result = {}
      // challenge_ids.forEach(id => {
      //   result[id] = []
      // })
      return response.data
    },
  },

  getters: {},
})
